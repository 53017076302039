import { ComponentPortal, ComponentType } from "@angular/cdk/portal";
import { inject, Injectable, signal, Signal } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";

import { IntercomService } from "../../intercom.service";

export class SidemenuComponent<Data> {
    data: Data;

    constructor(sideNavService: SideMenuService, actions?: SidemenuAction[]) {
        sideNavService.getData(this);
        if (actions) sideNavService.setActions(actions);
    }
}

export interface SidemenuAction {
    icon?: string;
    text: string;
    color?: "accent" | "default" | "primary" | "warn";
    isPrimary: boolean;
    action: () => void;
    disabled?: Signal<boolean>;
}

@Injectable({ providedIn: "root" })
export class SideMenuService {
    sideNavPortal: ComponentPortal<unknown> | undefined;
    sideNavDrawer: MatSidenav;
    data: unknown;

    width = "50vw";
    title: string;
    actions = signal<SidemenuAction[]>([]);

    private intercomService = inject(IntercomService);

    openSideMenu<X extends SidemenuComponent<D>, D>(data: D, component: ComponentType<X>) {
        this.sideNavPortal = new ComponentPortal(component);
        this.sideNavDrawer.autoFocus = false;
        this.sideNavDrawer.open();
        this.data = data;
        this.intercomService.hideIntercom();
        return this.sideNavPortal;
    }

    /** internal */
    getData<Data>(component: SidemenuComponent<Data>) {
        component.data = this.data as Data;
    }

    closeSideNav() {
        this.sideNavDrawer.close();
        this.intercomService.showIntercom();
        this.sideNavPortal = undefined;
        this.actions.set([]);
    }

    setSideNav(component: MatSidenav) {
        this.sideNavDrawer = component;
    }

    setActions(actions: SidemenuAction[]) {
        this.actions.set(actions);
    }
}
