<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content fxLayout="column">
    <p>{{ data.message }}</p>
    <form [formGroup]="form">
        <mat-form-field>
            <mat-label l10nTranslate>confirm_reason</mat-label>
            <input matInput formControlName="reason" required />
            @if (form.controls.reason.errors?.required) {
                <mat-error l10nTranslate>validation.field_required </mat-error>
            }
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
    <button mat-stroked-button l10nTranslate [fxShow]="data.showCancel" (click)="onCancel()">
        {{ data.cancelText ? data.cancelText : "cancel" }}
    </button>
    <button mat-flat-button color="primary" cdkFocusInitial l10nTranslate (click)="onOk()">
        {{ data.confirmText ? data.confirmText : "confirm" }}
    </button>
</mat-dialog-actions>
