import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog";
import { FlexLayoutModule } from "@ngbracket/ngx-layout";
import { ExtendedModule } from "@ngbracket/ngx-layout/extended";
import { L10nTranslateDirective } from "angular-l10n";

interface DialogData {
    title: string;
    message: string;
    showCancel?: boolean;
    showConfirm?: boolean;
    cancelText: string;
    confirmText?: string;
}

@Component({
    imports: [
        MatDialogModule,
        FlexLayoutModule,
        MatButtonModule,
        L10nTranslateDirective,
        ExtendedModule
    ],
    selector: "ws-confirm-popup",
    standalone: true,
    templateUrl: "./confirm-popup.component.html"
})
export class ConfirmPopupComponent {
    constructor(
        public dialogRef: MatDialogRef<ConfirmPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onCancel = () => this.dialogRef.close(false);
    onOk = () => this.dialogRef.close(true);
}
