<h1 mat-dialog-title data-cy="confirm_title">{{ data.title }}</h1>
<mat-dialog-content data-cy="confirm_message">
    <p>{{ data.message }}</p>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end start">
    <button mat-stroked-button l10nTranslate [fxShow]="data.showCancel" (click)="onCancel()">
        {{ data.cancelText ? data.cancelText : "cancel" }}
    </button>
    <button
        data-cy="confirm"
        mat-flat-button
        color="primary"
        cdkFocusInitial
        l10nTranslate
        [fxShow]="data.showConfirm"
        (click)="onOk()"
    >
        {{ data.confirmText ? data.confirmText : "confirm" }}
    </button>
</mat-dialog-actions>
