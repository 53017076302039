import { JsonPipe } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { FormControl, FormRecord, Validators } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { FlexLayoutModule, ExtendedModule } from "@ngbracket/ngx-layout";
import { L10nTranslateDirective } from "angular-l10n";

import { WsFormsImports } from "../../../ws-common";

interface DialogData {
    title: string;
    message: string;
    showCancel?: boolean;
    cancelText: string;
    confirmText?: string;
}

@Component({
    imports: [
        MatDialogModule,
        FlexLayoutModule,
        MatButtonModule,
        L10nTranslateDirective,
        ExtendedModule,
        WsFormsImports,
        JsonPipe
    ],
    selector: "ws-confirm-with-reason-popup",
    standalone: true,
    styleUrls: ["./confirm-with-reason-popup.component.scss"],
    templateUrl: "./confirm-with-reason-popup.component.html"
})
export class ConfirmWithReasonPopupComponent {
    form = new FormRecord({
        reason: new FormControl(null, Validators.required)
    });

    constructor(
        public dialogRef: MatDialogRef<ConfirmWithReasonPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    onCancel = () => this.dialogRef.close(false);
    onOk = () => {
        if (!this.form.valid) {
            this.form.markAsTouched();
            return;
        }
        this.dialogRef.close(this.form.value.reason);
    };
}
