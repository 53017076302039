import { SortEnum, MoneyAmount, SvgTemplate } from "@vre-wave/domain";

export class SortHelper {
    static groupBy<T, K extends keyof any>(arr: T[], valFn: (item: T) => K): Record<K, T[]> {
        return arr.reduce(
            (accumulator, item) => {
                const key = valFn(item);
                if (!accumulator[key]) {
                    accumulator[key] = [];
                }
                accumulator[key].push(item);
                return accumulator;
            },
            {} as Record<K, T[]>
        );
    }

    static SortBy<T, K extends keyof T>(values: T[], key: K, dir: "asc" | "desc" = "asc") {
        return values.sort((a, b) => {
            if (typeof a[key] === "boolean") {
                return a[key] === b[key] ? 0 : a[key] && dir === "asc" ? -1 : 1;
            } else if (this.isMoneyAmount(a[key])) {
                const x = (a[key] as unknown as MoneyAmount).amount;
                const y = (b[key] as unknown as MoneyAmount).amount;

                return dir === "asc" ? this.sortFnAsc(x, y) : this.sortFnDesc(x, y);
            } else if (typeof a[key] === "string") {
                return this.sortStringsFn(
                    a[key] as unknown as string,
                    b[key] as unknown as string,
                    dir
                );
            }

            return dir === "asc" ? this.sortFnAsc(a[key], b[key]) : this.sortFnDesc(a[key], b[key]);
        });
    }

    static compareFnClosestTo1X1(a: SvgTemplate, b: SvgTemplate) {
        const absRatioOfADistanceFromOne = Math.abs(1 - a.width / a.height);
        const absRatioOfBDistanceFromOne = Math.abs(1 - b.width / b.height);
        const compareValue = absRatioOfADistanceFromOne < absRatioOfBDistanceFromOne ? -1 : 1;
        return compareValue;
    }

    static getSortDir(e?: SortEnum): "asc" | "desc" {
        if (!e) {
            return "asc";
        } else if (e.includes("ASC")) {
            return "asc";
        } else if (e.includes("DESC")) {
            return "desc";
        }
        return "asc";
    }

    private static sortStringsFn = (a: string, b: string, dir: "asc" | "desc") =>
        dir === "asc"
            ? this.sortFnAsc(a.toLowerCase(), b.toLowerCase())
            : this.sortFnDesc(a.toLowerCase(), b.toLowerCase());

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static sortFnAsc = (a: any, b: any) => (a < b ? -1 : a > b ? 1 : 0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static sortFnDesc = (a: any, b: any) => (a < b ? 1 : a > b ? -1 : 0);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private static isMoneyAmount(o: any): o is MoneyAmount {
        return (o as MoneyAmount)?.amount > 0 || (o as MoneyAmount)?.amount === 0;
    }
}
