import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { TranslationService, TranslationKeys } from "@vre-wave/infrastructure";

import { ConfirmPopupComponent } from "../../../app/shared/confirm-popup/confirm-popup.component";
import { DialogService } from "../../dialog.service";

import { ConfirmWithReasonPopupComponent } from "./confirm-with-reason-popup/confirm-with-reason-popup.component";

export interface ConfirmActionServiceOptions {
    showCancel: boolean;
    showConfirm: boolean;
    cancelText: string;
    confirmText: string;
}

@Injectable({ providedIn: "root" })
export class ConfirmActionService {
    constructor(
        private dialogService: DialogService,
        private ts: TranslationService
    ) {}

    confirmAction$(
        title?: TranslationKeys,
        message?: TranslationKeys,
        options?: Partial<ConfirmActionServiceOptions>,
        variables?: { [key: string]: string }
    ): Observable<boolean> {
        const dialogTitle = title
            ? this.ts.translate(title, variables)
            : this.ts.translate("confirmService.Confirm_action");

        const dialogMessage = message ? this.ts.translate(message, variables) : "";
        const cancelText = options?.cancelText ?? "cancel";
        const confirmText = options?.confirmText ?? "confirm";

        const dialogRef = this.dialogService.openDialog(ConfirmPopupComponent, {
            data: {
                cancelText: cancelText,
                confirmText: confirmText,
                message: dialogMessage,
                showCancel: options?.showCancel ?? true,
                showConfirm: options?.showConfirm ?? true,
                title: dialogTitle
            },
            width: "500px"
        });

        return dialogRef.afterClosed();
    }

    confirmActionWithReason$(
        title?: TranslationKeys,
        message?: TranslationKeys,
        options?: Partial<ConfirmActionServiceOptions>,
        variables?: { [key: string]: string }
    ): Observable<string | boolean> {
        const dialogTitle = title
            ? this.ts.translate(title, variables)
            : this.ts.translate("confirmService.Confirm_action");

        const dialogMessage = message ? this.ts.translate(message, variables) : "";
        const cancelText = options?.cancelText ?? "cancel";
        const confirmText = options?.confirmText ?? "confirm";

        const dialogRef = this.dialogService.openDialog(ConfirmWithReasonPopupComponent, {
            data: {
                cancelText: cancelText,
                confirmText: confirmText,
                message: dialogMessage,
                showCancel: options?.showCancel ?? true,
                title: dialogTitle
            },
            width: "500px"
        });

        return dialogRef.afterClosed();
    }
}
